import * as React from "react"
import Layout from "../components/layout/Layout"
import JotformEmbed from "react-jotform-embed"

import "../scss/main.scss"

const FormPage = () => (
  <>
    <JotformEmbed
      src="https://form.jotform.com/210853811108045"
      scrolling={true}
    />
  </>
)

export default FormPage
